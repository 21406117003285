import React from 'react';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';

const TermsConditions = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Page Content */}
      <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6" style={{ fontFamily: 'Manrope, sans-serif' }}>
            Terms and Conditions
          </h1>

          <p className="text-lg text-gray-700 mb-10">
            Welcome to EDUkraine's Terms and Conditions page. Here you will find all the important policies and terms that govern
            the use of our services and the conduct expected from our volunteers and participants. Please read these documents
            carefully to understand your rights and responsibilities.
          </p>

          <ol className="list-decimal pl-6 text-lg text-blue-600 mb-10 space-y-4">
            <li>
              <Link to="/terms" className="hover:underline">Terms</Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/non-discrimination-policy" className="hover:underline">Non-Discrimination Policy</Link>
            </li>
            <li>
              <Link to="/child-protection-policy" className="hover:underline">Child Protection Policy</Link>
            </li>
            <li>
              <Link to="/volunteer-code-of-conduct" className="hover:underline">Volunteer Code of Conduct</Link>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
