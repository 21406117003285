import React from 'react';
import Navbar from '../components/Navbar'; // Assuming Navbar is imported
import { Link } from 'react-router-dom';

const EstablishChapter = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Page Content */}
      <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6" style={{ fontFamily: 'Manrope, sans-serif' }}>
            Establish a Chapter
          </h1>

          <p className="text-lg text-gray-700 mb-10">
            Join the Movement: Start an EDUkraine Chapter in Your Community
          </p>

          <p className="text-lg text-gray-700 mb-10">
            Are you passionate about helping Ukrainian refugees and want to make a difference in your community?
            Establishing an EDUkraine chapter is a powerful way to extend our mission and support more people in need.
            Here's how you can get started:
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Why Start a Chapter?</h2>
          <ul className="list-disc pl-6 text-gray-700 mb-10 space-y-2">
            <li>
              <span className="font-semibold">Make a Local Impact:</span> Help Ukrainian refugees in your area overcome
              language barriers and access new opportunities.
            </li>
            <li>
              <span className="font-semibold">Build Community:</span> Bring together like-minded individuals who are
              dedicated to making a positive change.
            </li>
            <li>
              <span className="font-semibold">Empower Others:</span> Provide valuable skills that enable refugees to
              succeed in their new environments.
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Steps to Establish a Chapter</h2>
          <ul className="list-disc pl-6 text-gray-700 mb-10 space-y-2">
            <li>
              <span className="font-semibold">Express Your Interest:</span> Fill out our Chapter Interest Form. We will
              contact you to discuss your goals and provide more information about the process.
            </li>
            <li>
              <span className="font-semibold">Initial Planning:</span> Identify local needs and potential volunteers.
              Develop a plan for outreach and recruitment in your community.
            </li>
            <li>
              <span className="font-semibold">Recruit Volunteers:</span> Gather a dedicated team of volunteers who share
              your passion for helping Ukrainian refugees. Use our volunteer registration form and guidelines to
              streamline the process.
            </li>
            <li>
              <span className="font-semibold">Launch Your Chapter:</span> Host an introductory meeting to kick off your
              chapter. Start offering English lessons and support services to Ukrainian refugees!
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Chapter Support</h2>
          <p className="text-lg text-gray-700 mb-6">
            EDUkraine is committed to supporting each chapter with:
          </p>
          <ul className="list-disc pl-6 text-gray-700 mb-10 space-y-2">
            <li>
              <span className="font-semibold">Training:</span> Comprehensive training sessions for chapter leaders and
              volunteers.
            </li>
            <li>
              <span className="font-semibold">Resources:</span> Access to a library of teaching materials, lesson plans,
              and promotional resources.
            </li>
            <li>
              <span className="font-semibold">Ongoing Support:</span> Regular check-ins, webinars, and a dedicated support
              team to assist with any challenges.
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Get Started</h2>
          <p className="text-lg text-gray-700 mb-10">
            Ready to make a difference? Fill out the Chapter Interest Form to start the process of establishing an EDUkraine chapter in your community!
          </p>

          {/* Apply Button */}
          <Link
            to="/apply"
            className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </>
  );
};

export default EstablishChapter;
