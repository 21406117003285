// src/pages/Home.jsx or src/App.jsx
import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import InfoBlocks from './InfoBlock';
import Footer from './Footer';
import VolunteerLearn from './Volunteer-Learn';
import HearFromThem from './Hear-From-Them'
import KeyFigures from './Key-Figures'

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <InfoBlocks/>
      <VolunteerLearn/>
      <HearFromThem/>
      <KeyFigures/>
      <Footer/>
    </div>
  );
};

export default Home;
