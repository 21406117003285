import React from 'react';
import Navbar from '../Navbar';

const Terms = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Page Content */}
      <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6" style={{ fontFamily: 'Manrope, sans-serif' }}>
            Terms
          </h1>

          <p className="text-lg text-gray-700 mb-10">
            Welcome to EDUkraine. By accessing and using our website, services, and resources, you agree to comply with and be bound by the following terms. Please read these terms carefully before using our services.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Acceptance of Terms</h2>
          <p className="text-lg text-gray-700 mb-6">
            By using our website and services, you agree to these Terms. If you do not agree to these terms, please do not use our services.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Use of Services</h2>
          <p className="text-lg text-gray-700 mb-6">
            As a volunteer, you must be at least 13 years old to use our services. As a student, you must be 9 years old and above. If you are under 18, you must have the permission of a parent or guardian.
            <br />
            You agree to not use our services for any unlawful or prohibited activities, including but not limited to:
            <ul className="list-disc pl-6 mt-2">
              <li>Harassment, abuse, or harm to others.</li>
              <li>Dissemination of inappropriate, offensive, or harmful content.</li>
              <li>Unauthorized access to our systems or accounts.</li>
              <li>Any activity that violates local, state, national, or international laws.</li>
            </ul>
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Intellectual Property</h2>
          <p className="text-lg text-gray-700 mb-6">
            All content, materials, and resources provided by EDUkraine, including but not limited to text, graphics, logos, and software, are the property of EDUkraine and are protected by intellectual property laws. You may use the content and materials provided by EDUkraine for personal, non-commercial purposes only. You may not reproduce, distribute, modify, or create derivative works without our explicit permission.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Privacy</h2>
          <p className="text-lg text-gray-700 mb-6">
            Your privacy is important to us. Please review our <a href="/privacy-policy" className="text-blue-600 hover:underline">Privacy Policy</a> to understand how we collect, use, and protect your personal information.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Limitation of Liability</h2>
          <p className="text-lg text-gray-700 mb-6">
            EDUkraine provides its services on an “as-is” and “as-available” basis. We do not guarantee the accuracy, completeness, or reliability of any content or materials provided. EDUkraine will not be liable for any direct, indirect, incidental, or consequential damages arising from your use of our services or inability to access our services.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Termination</h2>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Termination by EDUkraine:</strong> We reserve the right to suspend or terminate your access to our services at any time, without notice, for any violation of these Terms. Volunteers who fail to adhere to our policies or pose a risk to the safety and well-being of our students will be suspended or terminated. Grounds for dismissal include, but are not limited to:
            <ul className="list-disc pl-6 mt-2">
              <li>Violation of the EDUkraine Code of Conduct</li>
              <li>Inappropriate behavior or interactions with students</li>
              <li>Failure to comply with supervision and reporting requirements</li>
              <li>Any actions that compromise the safety and integrity of our programs</li>
            </ul>
            <strong>Termination by User:</strong> You may discontinue your use of our services at any time. Email <a href="mailto:info@edukraine.foundation" className="text-blue-600 hover:underline">info@edukraine.foundation</a> to do so.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Changes to Terms</h2>
          <p className="text-lg text-gray-700 mb-6">
            EDUkraine reserves the right to modify these Terms at any time. Any changes will be posted on our website, and your continued use of our services after such changes constitutes your acceptance of the new terms.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Contact Information</h2>
          <p className="text-lg text-gray-700 mb-6">
            For any questions or concerns regarding these Terms, please contact us at <a href="mailto:info@edukraine.foundation" className="text-blue-600 hover:underline">info@edukraine.foundation</a>.
          </p>

          <p className="text-lg text-gray-500">
            By using EDUkraine’s services, you acknowledge that you have read, understood, and agree to these Terms.
          </p>

          <p className="text-lg text-gray-500 mt-4">Last Updated: July 21, 2024</p>
        </div>
      </div>
    </>
  );
};

export default Terms;
