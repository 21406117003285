import React, { useState, useEffect } from 'react';
import logo from '../assets/edukrainelogo.png'; // Adjust the path based on your directory structure
import HeaderMarquee from './HeaderMarquee'; // Import the HeaderMarquee component

const Navbar = () => {
  const [dropdown, setDropdown] = useState(null);

  // Close dropdown on scroll
  useEffect(() => {
    const handleScroll = () => {
      setDropdown(null);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Toggle dropdown for a specific menu
  const handleDropdown = (menu) => {
    setDropdown(dropdown === menu ? null : menu);
  };

  return (
    <>
      {/* Navbar */}
      <nav className="bg-white shadow-lg py-4 relative z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-4">
          <div>
            <a href="#">
              <img src={logo} alt="EDUkraine Logo" className="h-12 w-auto" />
            </a>
          </div>
          <div className="flex items-center space-x-16 font-bold">
            {/* About Us Dropdown */}
            <div className="relative">
              <button onClick={() => handleDropdown('about')} className="flex items-center text-black">
                About Us <span className="ml-1" style={{ fontSize: '10px' }}>▼</span>
              </button>
              {dropdown === 'about' && (
                <div className="absolute top-full mt-2 bg-white shadow-md py-2 w-48 text-center border border-black rounded-md z-50">
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">About</a>
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Meet the Team</a>
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Terms</a>
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Contact</a>
                </div>
              )}
            </div>

            {/* Volunteer Dropdown */}
            <div className="relative">
              <button onClick={() => handleDropdown('volunteer')} className="flex items-center text-black">
                Volunteer <span className="ml-1" style={{ fontSize: '10px' }}>▼</span>
              </button>
              {dropdown === 'volunteer' && (
                <div className="absolute top-full mt-2 bg-white shadow-md py-2 w-48 text-center border border-black rounded-md z-50">
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Become a Volunteer</a>
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Volunteer Roles</a>
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Apply Now</a>
                </div>
              )}
            </div>

            {/* Learn Dropdown */}
            <div className="relative">
              <button onClick={() => handleDropdown('learn')} className="flex items-center text-black">
                Learn <span className="ml-1" style={{ fontSize: '10px' }}>▼</span>
              </button>
              {dropdown === 'learn' && (
                <div className="absolute top-full mt-2 bg-white shadow-md py-2 w-48 text-center border border-black rounded-md z-50">
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Resources</a>
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">FAQs</a>
                </div>
              )}
            </div>

            {/* Support Us Dropdown */}
            <div className="relative">
              <button onClick={() => handleDropdown('support')} className="flex items-center text-black">
                Support Us <span className="ml-1" style={{ fontSize: '10px' }}>▼</span>
              </button>
              {dropdown === 'support' && (
                <div className="absolute top-full mt-2 bg-white shadow-md py-2 w-48 text-center border border-black rounded-md z-50">
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Donate</a>
                  <a href="#" className="block px-6 py-3 hover:bg-gray-100">Partnerships</a>
                </div>
              )}
            </div>
          </div>

          {/* Language Selector */}
          <div className="relative">
            <button className="border rounded-full bg-gray-200 flex items-center space-x-2 px-2 py-1">
              <span role="img" aria-label="USA Flag" className="text-2xl">🇺🇸</span>
              <span className="ml-1" style={{ fontSize: '10px' }}>▼</span>
            </button>
          </div>
        </div>
      </nav>

      {/* Marquee Banner */}
      <HeaderMarquee /> {/* Replacing inline marquee with the component */}
    </>
  );
};

export default Navbar;
