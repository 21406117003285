import React from "react";
import logo from "../assets/edukrainelogo.png"; // Assuming you have the EDUkraine logo in assets
import instagramLogo from "../assets/social_media/instagram.png"; // Instagram logo path
import linkedinLogo from "../assets/social_media/linked_in.png"; // LinkedIn logo path
import facebookLogo from "../assets/social_media/facebook.png"; // Facebook logo path

const Footer = () => {
  return (
    <footer className="text-black">
      <div>
        <div className="flex">
          {/* Logo and Social Media */}
          <div className="flex basis-1/4 flex-col gap-8 justify-center px-[60px] pt-[50px] pb-[55px]">
            <a href="/">
              <img src={logo} alt="EDUkraine Logo" />
            </a>
            <div className="flex space-x-4">
              {/* Social Media Icons */}
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" className="h-6 w-6 hover:opacity-80" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <img src={linkedinLogo} alt="LinkedIn" className="h-6 w-6 hover:opacity-80" />
              </a>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <img src={facebookLogo} alt="Facebook" className="h-6 w-6 hover:opacity-80" />
              </a>
            </div>
            <p className="text-xs">© 2023-2024 EDUkraine. All rights reserved.</p>
          </div>

          {/* Footer Links */}
          <div className="grid basis-3/4 grid-cols-4 gap-x-[140px] text-left px-[65px] py-[60px] bg-yellow-400">
            {/* Column 1: About Us */}
            <div>
              <h4 className="font-bold text-xl mb-4">About Us</h4>
              <ul>
                <li>
                  <a href="#" className="hover:text-gray-700">About</a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-700">Meet the Team</a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-700">Contact</a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-700">Terms and Conditions</a>
                </li>
              </ul>
            </div>

            {/* Column 2: Volunteer */}
            <div>
              <h4 className="font-bold text-xl mb-4">Volunteer</h4>
              <ul>
                <li>
                  <a href="#" className="hover:text-gray-700">Become a Volunteer</a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-700">Establish a Chapter</a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-700">Volunteer Materials</a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-700">Volunteer Application</a>
                </li>
              </ul>
            </div>

            {/* Column 3: Student */}
            <div>
              <h4 className="font-bold text-xl mb-4">Learn</h4>
              <ul>
                <li>
                  <a href="#" className="hover:text-gray-700">Become a Student</a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-700">Student Application</a>
                </li>
              </ul>
            </div>

            {/* Column 4: Support Us */}
            <div>
              <h4 className="font-bold text-xl mb-4">Support Us</h4>
              <ul>
                <li>
                  <a href="#" className="hover:text-gray-700">Donate</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
