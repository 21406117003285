import React from "react";
import './SlavaMarquee.css'; // Adjust the path according to your folder structure

const SlavaMarquee = () => {
  return (
    <div className="slava-marquee">
      <div className="slava-track">
        {/* Repeat the content for smooth scrolling */}
        {[...Array(16)].map((_, i) => (
          <div key={i} className="slava-item flex items-center">
            <span className="text-[#FED400] text-lg font-extrabold mr-1">Slava</span>
            <span className="text-[#005ABA] text-lg font-extrabold">Ukraini!</span>
            <span className="ml-1">🇺🇦</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlavaMarquee;
