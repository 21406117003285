import React from "react";
import WendyOlga from "../assets/wendy_olga.jpeg";

const HearFromThem = () => {
  return (
    <div className="text-center py-10 px-[60px] bg-[#F1F1F1]">
      {/* Header */}

      <div className="">
        <h1
          className="text-left"
          style={{
            fontFamily: "'Reenie Beanie', cursive",
            fontSize: "65px",
            fontWeight: 500,
          }}
        >
          Hear from them.
        </h1>
        <p
          className="text-left mt-2"
          style={{
            color: "#000",
            fontFamily: "Manrope",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Find out what volunteers and students say about their EDUkraine
          experience.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[60px] mt-10">
        <div className="flex flex-col gap-10">
          <div className="bg-[#020659] text-white p-6 rounded-2xl text-left text-xl">
            <p className="mb-5">
              Thanks to EDUkraine, I met Ayaan. He is a cool person and we have
              interesting meetings. I am really glad there are many people in
              the US that can find free time to help improve my English!
            </p>
            <p>
              Denys B.
              <br />
              EDUkraine Student
            </p>
          </div>

          <div className="bg-[#020659] text-white p-6 rounded-2xl text-left text-xl">
            <img
              className="mx-auto rounded-lg h-[645px]"
              src={WendyOlga}
              alt="Wendy H. and Olga M."
            />
            <p className="mt-4 text-white text-left text-[20px]">
              Wendy H. (EDUkraine Volunteer, Left) and Olga M. (EDUkraine
              Student, Right)
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-10">
          <div className="bg-[#020659] text-white p-6 rounded-2xl text-left text-xl">
            <p className="mb-5">
              EDUkraine has already become a big part of my life. Every week,
              when I talk to my volunteer, I am getting better and better.
              Sessions give me confidence and help me improve my English level
              significantly. In the future, EDUkraine will open up even more
              opportunities for me to learn and grow professionally.
            </p>
            <p>
              Sofia P.
              <br />
              EDUkraine Student
            </p>
          </div>

          <div className="bg-[#020659] text-white p-6 rounded-2xl text-left text-xl">
            <p className="mb-5">
              Thanks to the program, I met my volunteer Prisha, and it's cool!
              EDUkraine is a very good project to help you improve your spoken
              English and communicate with interesting people. The experience
              with EDUkraine will help me learn more about the volunteer
              country, improve my communication skills with different people,
              and make my week more diverse and fun, thanks to these meetings.
              Thank you very much for this wonderful opportunity.
            </p>
            <p>
              Mary F.
              <br />
              EDUkraine Student
            </p>
          </div>

          <div className="bg-[#020659] text-white p-6 rounded-2xl text-left text-xl">
            <p className="mb-5">
              Thanks to the EDUkraine program, I met my volunteer, Marvin, who
              is a versatile person with excellent life experience and
              tremendous patience. He was very gallant in his approach to
              teaching and explaining, not criticizing and not pressuring me
              with his instructions. The lessons were interesting and valuable
              for me. I improved my pronunciation, got quicker speech, and
              worked out the complex fear of speaking in English. I think that
              further lessons will bring even more benefits. Thank you to the
              program and volunteers for such work.
            </p>
            <p>
              Natalia S.
              <br />
              EDUkraine Student
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HearFromThem;
