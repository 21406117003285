import React from 'react';

const InfoBlock = () => {
  return (
    <div>
      {/* Blue and Yellow Ribbon */}
      <div className="w-full h-2" style={{ backgroundColor: '#005ABA' }}></div>
      <div className="w-full h-2" style={{ backgroundColor: '#FED400' }}></div>

      {/* Content Block (Blue Section) */}
      <div className="bg-[#020659] text-[#F1F1F1] flex items-center" style={{ width: '1512px', height: '201px', padding: '0 60px' }}>
        <div className="max-w-full text-left">
          <h2
            className="font-extrabold"
            style={{
              fontFamily: 'Manrope, sans-serif',
              fontSize: '45px',
              lineHeight: '61.47px',
              fontWeight: 800,
              color: '#F1F1F1',
            }}
          >
            As of February 2024, <span style={{ textDecoration: 'underline' }}>3.7 million</span> Ukrainians have been internally displaced and <span style={{ textDecoration: 'underline' }}>6.5 million</span> have fled the war as refugees.
          </h2>
        </div>
      </div>

      {/* F1F1F1 Block */}
      <div className="bg-[#F1F1F1] text-[#020659] flex items-center" style={{ width: '1512px', height: '201px', padding: '0 60px' }}>
        <div className="max-w-full text-left">
          <h3
            className="font-extrabold"
            style={{
              fontFamily: 'Manrope, sans-serif',
              fontSize: '45px',
              lineHeight: 'normal',
              fontWeight: 800,
              color: '#020659',
            }}
          >
            EDUkraine bridges the language gap by providing individualized English speaking lessons <span style={{ textDecoration: 'underline' }}>free of charge</span>.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default InfoBlock;
