import React from 'react';
import backgroundImage from '../assets/Edukraine.jpg'; // Adjust the path based on your directory structure

const Hero = () => {
  return (
    <div className="relative h-screen">
      {/* Background image and main Hero content */}
      <div
        className="relative h-full flex items-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="relative z-10 pl-6 sm:pl-14 lg:pl-22">
          <div className="text-left">
            <h1
              className="text-6xl font-extrabold tracking-tight"
              style={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.4', letterSpacing: '-1px' }} // Increased size and line height
            >
              <span className="text-blue-600">EMPOWERING</span> <br />
              <span className="text-white">UKRAINIAN</span> <br />
              <span className="text-white">REFUGEES</span> <br />
              <span className="text-white">THROUGH</span> <br />
              <span className="text-yellow-400">EDUCATION</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
