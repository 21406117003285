import React from 'react';
import Navbar from '../components/Navbar'; // Assuming Navbar is imported

const Contact = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Page Content */}
      <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6" style={{ fontFamily: 'Manrope, sans-serif' }}>
            Contact
          </h1>

          <p className="text-lg text-gray-700 mb-10">
            Thank you for your interest in EDUkraine. We are here to support and answer any questions you may have.
            Whether you are a potential student, a volunteer, a chapter organizer, or simply someone who wants to learn
            more about our mission, we'd love to hear from you.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Email</h2>
          <p className="text-lg text-gray-700 mb-10">
            Please email us at: 
            <a href="mailto:info@edukraine.foundation" className="text-blue-600 hover:text-blue-800 ml-2">info@edukraine.foundation</a>
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Mailing Address</h2>
          <p className="text-lg text-gray-700 mb-10">
            You can also reach us by mail at: <br />
            Jayden Yoon / Vlad Mishyn <br />
            St. Mark's School <br />
            25 Marlboro Rd <br />
            Southborough, MA 01772 <br />
            United States
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Form</h2>
          <p className="text-lg text-gray-700 mb-6">
            Alternatively, you can fill out the contact form on our website, and we will get back to you as soon as
            possible.
          </p>

          {/* Contact Form */}
          <form className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name (required)
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12" // Increased height
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email (required)
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12" // Increased height
                required
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <button
              type="submit"
              className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700"
            >
              Submit
            </button>
          </form>

          {/* Embedded Google Map */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Location</h2>
            <iframe
              title="Map of Southborough, MA"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4727.4221307285!2d-71.5276443826695!3d42.308849931263106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e39c9ed422d3f9%3A0x37dfcd9301b11396!2sSouthborough%2C%20MA%2001772%2C%20USA!5e0!3m2!1sen!2s!4v1694690404850!5m2!1sen!2s"
              width="100%"
              height="400"
              className="border-0"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
