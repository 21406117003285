import React from 'react';
import Navbar from '../components/Navbar'; // Assuming Navbar is imported
import { Link } from 'react-router-dom';

const BecomeVolunteer = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Page Content */}
      <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6" style={{ fontFamily: 'Manrope, sans-serif' }}>
            Become a Volunteer
          </h1>

          <p className="text-lg text-gray-700 mb-10">
            Make a Difference with EDUkraine
          </p>

          <p className="text-lg text-gray-700 mb-10">
            Volunteering with EDUkraine is a rewarding opportunity to help Ukrainian refugees rebuild their lives
            through the power of language. Join our dedicated team and make a significant impact in our students' lives
            by providing them with the skills they need to succeed in new environments.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Why Volunteer with Us?</h2>
          <ul className="list-disc pl-6 text-gray-700 mb-10 space-y-2">
            <li>
              <span className="font-semibold">Impact Lives:</span> Your efforts will directly help Ukrainian refugees
              overcome language barriers and access opportunities for education, employment, and community integration.
            </li>
            <li>
              <span className="font-semibold">Develop Skills:</span> Enhance your teaching, communication, and
              cross-cultural skills while gaining valuable experience in a supportive and collaborative environment.
            </li>
            <li>
              <span className="font-semibold">Be Part of a Community:</span> Join a passionate team of volunteers and
              students who are dedicated to making a positive change through EDUkraine’s mission.
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Volunteer Roles</h2>
          <ul className="list-disc pl-6 text-gray-700 mb-10 space-y-2">
            <li>
              <span className="font-semibold">English Tutors:</span> Provide one-on-one English conversational sessions
              to Ukrainian refugees of all proficiency levels (A1-C2).
            </li>
            <li>
              <span className="font-semibold">Curriculum Developers:</span> Help create engaging and effective learning
              materials tailored to our students' needs.
            </li>
            <li>
              <span className="font-semibold">Support Volunteers:</span> Assist with administrative tasks, outreach, and
              event planning to help our organization run smoothly.
            </li>
            <li>
              <span className="font-semibold">Chapter Leader:</span> Create an EDUkraine chapter at your school or local
              community. For more information, visit our Establish a Chapter page.
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">How to Get Started</h2>
          <ol className="list-decimal pl-6 text-gray-700 mb-10 space-y-2">
            <li>
              <span className="font-semibold">Apply Online:</span> Fill out our volunteer application form{' '}
              <Link to="/apply" className="text-blue-600 hover:text-blue-800 underline">
                here
              </Link>
              . We will review your application and match you with a suitable role based on your skills and interests.
            </li>
            <li>
              <span className="font-semibold">Interview:</span> Participate in a brief interview with a member from our
              volunteer interview team to discuss your goals and how you can best contribute to our mission.
            </li>
            <li>
              <span className="font-semibold">Training:</span> Attend our training sessions to learn more about our
              teaching methods, resources, and best practices for supporting our students.
            </li>
            <li>
              <span className="font-semibold">Start Volunteering:</span> Begin your journey as an EDUkraine volunteer and
              start making a difference!
            </li>
          </ol>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Join Us</h2>
          <p className="text-lg text-gray-700 mb-10">
            Are you ready to make a difference? Apply now to become a volunteer with EDUkraine and help Ukrainian
            refugees build a brighter future.
          </p>

          {/* Apply Button */}
          <Link
            to="/apply"
            className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </>
  );
};

export default BecomeVolunteer;
