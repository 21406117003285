import React from "react";
import PartnerOne from "../assets/partners/partner_1.png";
import PartnerTwo from "../assets/partners/partner_2.png";
import PartnerThree from "../assets/partners/partner_3.png";
import PartnerFour from "../assets/partners/partner_4.png";
import PartnerFive from "../assets/partners/partner_5.png";
import SlavaMarquee from "./SlavaMarquee"; // Import the new SlavaMarquee component

const StatsSection = () => {
  return (
    <div>
      {/* Key Figures Section */}
      <div className="py-10">
        <div className="mx-auto">
          <h2 className="text-5xl font-bold text-center">Key Figures</h2>
          <div className="flex flex-col lg:flex-row items-center mx-24 gap-32">
            
            <div className="text-center lg:mb-0">
              <p
                style={{
                  fontFamily: "'Reenie Beanie', cursive",
                  fontSize: "65px",
                  fontWeight: 500,
                }}
                className="text-[#005ABA]"
              >
                35+
              </p>
              <p className="text-black text-xl">
                Total number of countries represented by volunteers & refugees.
                25+ US states.
              </p>
            </div>

            <div className="text-center lg:mb-0">
              <p
                style={{
                  fontFamily: "'Reenie Beanie', cursive",
                  fontSize: "65px",
                  fontWeight: 500,
                }}
                className="text-[#005ABA]"
              >
                500+
              </p>
              <p className="text-black text-xl">
                Total people served by EDUkraine.
              </p>
            </div>
            
            <div className="text-center lg:mb-0">
              <p
                style={{
                  fontFamily: "'Reenie Beanie', cursive",
                  fontSize: "65px",
                  fontWeight: 500,
                }}
                className="text-[#005ABA]"
              >
                $30,000+
              </p>
              <p className="text-black text-xl">
                Total monetary value of volunteer hours.{" "}
                <a href="#" className="text-blue-500 underline">
                  See how this is calculated.
                </a>
              </p>
            </div>
          </div>
          {/* Flags Section */}
          <div className="flex overflow-auto my-10">
            <div className="flex space-x-4 mx-auto">
              <img
                src="/path/to/flag1.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag2.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag3.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag1.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag2.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag3.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag1.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag2.png"
                alt="Country Flag"
                className="h-8"
              />
              <img
                src="/path/to/flag3.png"
                alt="Country Flag"
                className="h-8"
              />
            </div>
          </div>
        </div>

        {/* Our Partners Section */}
        <div className="mx-auto">
          <h2 className="text-5xl font-bold text-center my-10">Our Partners</h2>
          <div className="flex justify-center items-center space-x-14 px-20">
            {/* Partner Logos */}
            <img src={PartnerOne} className="h-[100px]" />
            <img src={PartnerTwo} className="h-[100px]" />
            <img src={PartnerThree} className="h-[100px]" />
            <img src={PartnerFour} className="h-[100px]" />
            <img src={PartnerFive} className="h-[100px]" />
          </div>
        </div>
      </div>

      {/* Slava Ukraina Marquee */}
      <SlavaMarquee /> {/* Replacing the static section with the animated marquee */}
    </div>
  );
};

export default StatsSection;
