import React from "react";
import './HeaderMarquee.css'; // Ensure this path matches your folder structure

const HeaderMarquee = () => {
  return (
    <div className="bg-[#020659] py-1 relative z-10 overflow-hidden">
      <div className="marquee">
        <div className="track">
          <span>Join Us in Making a Difference! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>You Can Help! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>Join Us in Making a Difference! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>You Can Help! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>Join Us in Making a Difference! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>You Can Help! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

          {/* Duplicate the content for seamless scrolling */}
          <span>Join Us in Making a Difference! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>You Can Help! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>Join Us in Making a Difference! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>You Can Help! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>Join Us in Making a Difference! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>You Can Help! &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderMarquee;
