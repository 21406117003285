import { FaHandHoldingHeart, FaBrain } from "react-icons/fa"; // Importing the icons
import React from "react";

const VolunteerLearn = () => {
  return (
    <div
      style={{
        background:
          'repeat left center / contain url(https://cdn.jsdelivr.net/npm/twemoji@14.0.2/assets/svg/1f1fa-1f1e6.svg)',
      }}
    >
      {/* Header */}
      <div className="py-8 px-[60px]">
        <h1
          className="text-left"
          style={{
            fontFamily: "'Reenie Beanie', cursive",
            fontSize: '65px',
            fontWeight: 500,
          }}
        >
          Join the project.
        </h1>
        <p
          className="text-left mt-2"
          style={{
            color: '#000',
            fontFamily: 'Manrope',
            fontSize: '30px',
            fontWeight: 500,
          }}
        >
          Together, we can build a brighter future, one lesson at a time.
        </p>
      </div>

      {/* Volunteer and Learn Section */}
      <div className="flex justify-center space-x-8 py-8">
        {/* Volunteer Card */}
        <div className="bg-[#F1F1F1] text-black rounded-2xl shadow-md w-[420px]">
          <div
            style={{
              backgroundColor: '#005ABA',
              height: '158px',
              borderRadius: '15px 15px 0 0',
              position: 'relative',
            }}
          >
            <h2
              className="absolute bottom-0 left-4"
              style={{
                color: '#FED400',
                fontFamily: 'Manrope',
                fontSize: '45px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Volunteer
            </h2>
            <span
              style={{
                fontSize: '45px',
                fontWeight: 500,
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            >
              🙋‍♂️🙋‍♀️
            </span>
          </div>
          <p
            className="m-4 text-left"
            style={{
              color: '#000',
              textAlign: 'justify',
              fontFamily: 'Manrope',
              fontSize: '20px',
              fontWeight: 500,
            }}
          >
            Are you passionate about making a difference in the lives of Ukrainian refugees? Can you spare 30 minutes a week? Want to enhance your teaching, communication, and cross-cultural skills while gaining valuable experience? Join our team of dedicated volunteers and help provide valuable lessons.
          </p>
          <div className="mt-4 mb-7 flex space-x-10 justify-center">
            <button
              style={{
                width: '128px',
                height: '35px',
                borderRadius: '15px',
                backgroundColor: '#005ABA',
                color: '#FED400',
                fontFamily: 'Manrope',
                fontSize: '20px',
                fontWeight: 800,
              }}
            >
              Learn More
            </button>
            <button
              style={{
                width: '128px',
                height: '35px',
                borderRadius: '15px',
                backgroundColor: '#005ABA',
                color: '#FED400',
                fontFamily: 'Manrope',
                fontSize: '20px',
                fontWeight: 800,
              }}
            >
              Apply Now
            </button>
          </div>
        </div>

        {/* Learn Card */}
        <div className="bg-[#F1F1F1] text-black rounded-2xl shadow-md w-[420px]">
          <div
            style={{
              backgroundColor: '#FED400',
              height: '158px',
              borderRadius: '15px 15px 0 0',
              position: 'relative',
            }}
          >
            <h2
              className="absolute bottom-0 left-4"
              style={{
                color: '#020659',
                fontFamily: 'Manrope',
                fontSize: '45px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Learn
            </h2>
            <span
              style={{
                fontSize: '45px',
                fontWeight: 500,
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            >
              📚🧠
            </span>
          </div>
          <p
            className="m-4 text-left"
            style={{
              color: '#000',
              textAlign: 'justify',
              fontFamily: 'Manrope',
              fontSize: '20px',
              fontWeight: 500,
            }}
          >
            Are you a Ukrainian refugee looking to improve your English skills? EDUkraine is here to help you! Our program provides personalized one-on-one English-speaking lessons tailored to each student's needs. We welcome students of all proficiency levels (A1-C2), and our services are 100% free.
          </p>
          <div className="mt-4 mb-7 flex space-x-10 justify-center">
            <button
              style={{
                width: '128px',
                height: '35px',
                borderRadius: '15px',
                backgroundColor: '#005ABA',
                color: '#FED400',
                fontFamily: 'Manrope',
                fontSize: '20px',
                fontWeight: 800,
              }}
            >
              Learn More
            </button>
            <button
              style={{
                width: '128px',
                height: '35px',
                borderRadius: '15px',
                backgroundColor: '#005ABA',
                color: '#FED400',
                fontFamily: 'Manrope',
                fontSize: '20px',
                fontWeight: 800,
              }}
            >
              Enroll Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerLearn;
