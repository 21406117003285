import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import BecomeVolunteer from './components/BecomeVolunteer';
import EstablishChapter from './components/EstablishChapter';
import Contact from './components/Contact';
import TermsConditions from './components/TermsPages/TnC'; // Updated path for TermsConditions
import Terms from './components/TermsPages/Terms'; // Updated path for Terms
import PrivacyPolicy from './components/TermsPages/PrivacyPolicy'; // Import Privacy Policy

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/volunteer/become" element={<BecomeVolunteer />} />
        <Route path="/volunteer/chapter" element={<EstablishChapter />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/terms" element={<Terms />} /> {/* Route for the Terms page */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Route for the Privacy Policy */}
      </Routes>
    </Router>
  );
}

export default App;
