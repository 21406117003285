import React from 'react';
import Navbar from '../Navbar'; // Adjusted for Navbar
import Footer from '../Footer'; // Assuming you have a footer component

const PrivacyPolicy = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Page Content */}
      <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6" style={{ fontFamily: 'Manrope, sans-serif' }}>
            Privacy Policy
          </h1>

          <p className="text-lg text-gray-700 mb-10">
            At EDUkraine, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our services.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Information Collection</h2>
          <p className="text-lg text-gray-700 mb-6">
            We collect personal information that you voluntarily provide to us. This includes but is not limited to your name, email address, phone number, address, and other details.
            <br />
            We may collect information about how you access and use our services, including your IP address, browser type, and usage patterns.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Use of Information</h2>
          <p className="text-lg text-gray-700 mb-6">
            We use your personal information to provide, maintain, and improve our services, including communication and support.
            <br />
            We may use your contact information to send you updates, newsletters, and other information related to our services. You can opt out of these communications at any time.
            <br />
            We use the information provided to match volunteers with mentees, ensuring that both parties have a rewarding and effective learning experience.
            <br />
            Volunteers may be subject to a comprehensive background check, including criminal history, to ensure they meet our safety standards.
            <br />
            We use usage data to analyze and improve our services, ensuring a better experience for our users.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Information Sharing</h2>
          <p className="text-lg text-gray-700 mb-6">
            We do not share your personal information with third parties without your consent, except as required by law or to protect our rights.
            <br />
            We may share information with trusted service providers who assist us in operating our services, provided they adhere to our privacy standards.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Data Security</h2>
          <p className="text-lg text-gray-700 mb-6">
            We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, or misuse.
            <br />
            We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Your Rights</h2>
          <p className="text-lg text-gray-700 mb-6">
            You have the right to access and update your personal information. You can request corrections to any inaccurate or incomplete data.
            <br />
            You can request the deletion of your personal information, subject to any legal obligations for data retention.
            <br />
            You can opt out of receiving communications from us at any time by contacting us directly.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Changes to Privacy Policy</h2>
          <p className="text-lg text-gray-700 mb-6">
            EDUkraine reserves the right to modify this Privacy Policy at any time. Any changes will be posted on our website, and your continued use of our services constitutes your acceptance of the new terms.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Information</h2>
          <p className="text-lg text-gray-700 mb-6">
            For any questions or concerns regarding our Privacy Policy, please contact us at <a href="mailto:info@edukraine.foundation" className="text-blue-600 hover:underline">info@edukraine.foundation</a>.
          </p>

          <p className="text-lg text-gray-500 mt-4">Last Updated: July 21, 2024</p>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
